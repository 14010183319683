import styled, { css } from 'styled-components';

import { BREAKPOINTS } from '@core/constants/styles';

import arrowLeft from '@features/landing/assets/icons/arrow_left.svg?url';
import arrowLeftHover from '@features/landing/assets/icons/arrow_left_hover.svg?url';
import arrowRight from '@features/landing/assets/icons/arrow_right.svg?url';
import arrowRightHover from '@features/landing/assets/icons/arrow_right_hover.svg?url';

const ARROW_PADDING = 40;
const ARROW_SIZE = 52;

const controlArrowStyles = css`
  position: absolute;
  top: 50%;
  left: ${ARROW_PADDING}px;
  display: none;
  width: ${ARROW_SIZE}px;
  height: ${ARROW_SIZE}px;
  transform: translateY(-50%);
  cursor: pointer;
  z-index: 2;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: block;
  }
`;

export const ArrowLeft = styled.div`
  ${controlArrowStyles};
  display: none;
  background: url(${arrowLeft});
  background-size: cover;

  &:hover {
    background: url(${arrowLeftHover});
  }
`;

export const ArrowRight = styled.div`
  ${controlArrowStyles};
  left: calc(100% - ${ARROW_PADDING}px - ${ARROW_SIZE}px);
  background: url(${arrowRight});
  background-size: cover;

  &:hover {
    background: url(${arrowRightHover});
  }
`;

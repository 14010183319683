import InvestmentConstructionCycle from '@core/components/AntiCrisisProgramComponents/InvestmentConstructionCycle';
import YandexMetrika from '@core/components/YandexMetrika';

import AntiCrisisProgram from '@features/landing/components/AntiCrisisProgram';
// import Feedback from '@features/landing/components/Feedback';
import HousingCommissioning from '@features/landing/components/HousingCommissioning';
// import InfrastructureMenu from '@features/landing/components/InfrastructureMenu';
import MainFactors from '@features/landing/components/MainFactors';
import NationalGoals from '@features/landing/components/NationalGoals';
import News from '@features/landing/components/News';
import Organizations from '@features/landing/components/Organizations';
import RegulatoryWork from '@features/landing/components/RegulatoryWork';
import MediaBank from '@features/media-bank';

import BannerBuildingFuture from './components/BannerBuildingFuture';
import EncyclopediaRedirect from './components/EncyclopediaRedirect';
import PromoBannerPopup from './components/PromoBannerPopup';

const Landing = () => (
  <>
    <AntiCrisisProgram />
    <MainFactors />
    <News />
    <NationalGoals />
    {/* Скрыто в задаче ERS-36549 */}
    {/* <InfrastructureMenu /> */}
    <InvestmentConstructionCycle />
    <RegulatoryWork />
    <HousingCommissioning />
    <EncyclopediaRedirect />
    <MediaBank />
    <BannerBuildingFuture />
    <Organizations />
    <PromoBannerPopup />
    {/* Временно скрыто пока не готов бэк */}
    {/* <Feedback /> */}

    <YandexMetrika />
  </>
);

export default Landing;

import styled, { css } from 'styled-components';

import { Button } from '@core/UI/Button';
import { Layout as LayoutUI } from '@core/UI/Layout';
import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import stroycomplexCardBackground from '@features/landing/assets/images/stroycomplex_card_background.png';

const MOBILE_GRID_GAP = 16;
const GRID_GAP = 24;

export const Layout = styled(LayoutUI)`
  padding-top: 0;
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${MOBILE_GRID_GAP}px;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: ${GRID_GAP}px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    grid-template-columns: repeat(4, 1fr);
  }
`;

export const News = styled.div`
  position: relative;
  padding: 20px 24px;
  background: ${colors.aquaHaze};
  border-radius: 12px;
  z-index: 1;
  cursor: pointer;

  @media ${BREAKPOINTS.DESKTOP} {
    min-height: 232px;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      right: calc(0% + 16px);
      width: 132px;
      height: 154px;
      background: url(${stroycomplexCardBackground.src}) no-repeat;
      background-size: contain;
      opacity: 0.52;
      z-index: -1;
    }
  }
`;

export const NewsDate = styled(Typography).attrs({ type: 'p2', color: 'riverBlue' })``;

export const NewsTitle = styled(Typography).attrs({ type: 'p1', color: 'riverBlue' })`
  margin-top: 6px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 20px;
  }
`;

export const ShowMoreButton = styled(Button).attrs({ type: 'secondary' })`
  width: 100%;
  margin-top: 16px;

  @media ${BREAKPOINTS.DESKTOP} {
    width: auto;
    margin: 16px auto 0;
  }
`;

export const PreloaderStyles = css`
  color: ${colors.chambrayBlue};
  background-color: white;
  margin-top: 32px;
`;

import Image from 'next/image';
import styled from 'styled-components';

import { Layout as LayoutUI } from '@core/UI/Layout';
import { BREAKPOINTS, DEVICES, PAGE_PADDINGS } from '@core/constants/styles';

export const Layout = styled(LayoutUI)`
  @media ${DEVICES.MOBILE} {
    padding: ${PAGE_PADDINGS.MOBILE}px 0 0 0;
  }

  @media ${DEVICES.TABLET} {
    padding: 0;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding-top: 0;
    padding-bottom: 0;
  }
`;

export const BannerImg = styled(Image)`
  width: 100%;
  height: auto;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    border-radius: 8px;
  }
`;

import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';

import * as UIFileLink from './components/FileLink/FileLink.styles';

export const FilesContainer = styled.div`
  margin-top: 24px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${UIFileLink.FileLink} + ${UIFileLink.FileLink} {
    margin-top: 8px;
  }
`;

export const Heading = styled(Typography).attrs({ type: 'h3' })`
  margin-bottom: 8px;
`;

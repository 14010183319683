import * as UI from './AntiCrisisProgram.styles';
import Program from './components/Program';
import Quote from './components/Quote';
import { IAntiCrisisProps } from './types';

const AntiCrisisProgram = (props: IAntiCrisisProps) => {
  const { variant = 'regular' } = props;

  return (
    <UI.Container>
      <Program variant={variant} />
      <Quote />
    </UI.Container>
  );
};

export default AntiCrisisProgram;

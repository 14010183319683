import Link from 'next/link';
import { useRouter } from 'next/router';
import { memo, useEffect, useState } from 'react';

import ToggleButtonGroup from '@core/UI/ToggleButtonGroup';
import { Typography } from '@core/UI/Typography';

import * as UI from './MediaBank.styles';
import { EMediaBankType, options } from './config';

const MediaBank = () => {
  const [selectedTab, setSelectedTab] = useState(EMediaBankType.PHOTOS);
  const { query } = useRouter();

  useEffect(() => {
    if (query.videoId) {
      setSelectedTab(EMediaBankType.VIDEO);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UI.MediaLayout id="media">
      <UI.Title>Медиаматериалы</UI.Title>
      <UI.Wrapper>
        <ToggleButtonGroup
          options={options}
          styles={UI.toggleButtonGroupStyles}
          value={selectedTab}
          onChange={setSelectedTab}
        />
        <Link href={options[selectedTab].link.href}>
          <Typography
            color="chambrayBlue"
            tag="span"
            transform="uppercase"
            type="p2"
          >
            {options[selectedTab].link.label}
          </Typography>
        </Link>
      </UI.Wrapper>

      {options[selectedTab].content}
    </UI.MediaLayout>
  );
};

export default memo(MediaBank);

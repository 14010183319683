import styled, { css } from 'styled-components';

import { Layout } from '@core/UI/Layout';
import { Typography } from '@core/UI/Typography';
import { HEADER_MOBILE_HEIGHT, HEADER_DESKTOP_HEIGHT } from '@core/components/Header/Header.styles';
import { BREAKPOINTS } from '@core/constants/styles';

export const MediaLayout = styled(Layout)`
  scroll-margin-top: ${HEADER_MOBILE_HEIGHT}px;

  @media ${BREAKPOINTS.TABLET} {
    scroll-margin-top: ${HEADER_DESKTOP_HEIGHT}px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    scroll-margin-top: ${HEADER_DESKTOP_HEIGHT}px;
  }
`;

export const Title = styled(Typography).attrs({ type: 'h3', lineHeight: '28px' })`
  font-size: 21px;
  margin-bottom: 24px;
  scroll-margin-top: ${HEADER_MOBILE_HEIGHT}px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 28px;
    line-height: 36px;
    text-align: right;
    scroll-margin-top: ${HEADER_DESKTOP_HEIGHT}px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    font-size: 40px;
    line-height: 50px;
    text-align: left;
    margin-bottom: 30px;
    scroll-margin-top: ${HEADER_DESKTOP_HEIGHT}px;
  }
`;

export const Wrapper = styled.div`
  margin-bottom: 16px;

  @media ${BREAKPOINTS.TABLET} {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
  }s
`;

export const toggleButtonGroupStyles = css`
  margin-bottom: 16px;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 0px;
  }
`;

import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { omitProps } from '@core/utils/styles';

import { IToggleButtonProps } from './types';

const getVariantLineStyles = (active: IToggleButtonProps['active'], color: IToggleButtonProps['color'] = 'blue') => css`
  padding: 15px;
  border: none;
  border-top: 1px solid transparent;
  border-radius: 0px;
  background-color: ${colors.lightGrey};

  & ${Typography} {
    font-size: 16px;
    line-height: 20px;
    color: ${colors.riverBlue};
  }

  ${css`
    ${(active ? '' : '&:hover:not(:disabled)')} {
      border-top-color: ${colors[color]};
      background-color: ${colors.white};

      & ${Typography} {
        color: ${colors.prussianBlue};
      }
    }
  `}
`;

const getVariantCardStyles = (active: IToggleButtonProps['active'], color: IToggleButtonProps['color'] = 'mystic') => css`
  padding: 11px 15px;
  border: 1px solid transparent;
  border-radius: 4px;
  outline: none;
  color: ${colors.chambrayBlue};
  background-color: ${colors.white};

  ${css`
    ${(active ? '' : '&:hover:not(:disabled)')} {
      background-color: ${colors.chambrayBlue};

      & ${Typography} {
        color: ${colors[color]};
      }
    }
  `}

  &:disabled {
    color: ${colors.white};
    background-color: ${colors.softGrey};
  }
`;

const getStylesByVariant = (variant: IToggleButtonProps['variant']) => {
  switch (variant) {
    case 'line':
      return getVariantLineStyles;
    case 'card':
    default:
      return getVariantCardStyles;
  }
};

export const ToggleButton = styled
  .button
  .withConfig({
    shouldForwardProp: omitProps(['loading']),
  })<Pick<IToggleButtonProps, 'styles' | 'active' | 'loading' | 'color' | 'variant'>>`
  display: flex;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  &:focus-visible {
    outline: 1px solid ${colors.chambrayBlue};
    outline-offset: 1px;
  }

  &:disabled {
    cursor: not-allowed;
  }

  ${({ loading }) => loading && css`
    color: ${colors.white};

    ${Typography} {
      opacity: 0;
    }
  `}

  ${Typography} {
    display: flex;
    font-size: 20px;
    line-height: 24px;
    white-space: nowrap;
  }

  ${({ variant, active, color }) => getStylesByVariant(variant)(active, color)}

  ${({ styles }) => styles}
`;

export const PreloaderStyles = css`
  background: none;
  pointer-events: none;
`;

import AntiCrisisProgram from '@core/components/AntiCrisisProgram';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';

import * as UI from './AntiCrisisProgram.styles';

const AntiCrisisProgramWrapper = () => (
  <UI.Layout id={LANDING_ANCHORS_ROUTES.ANTI_CRISIS_STRATEGY}>
    <AntiCrisisProgram variant="landing" />
  </UI.Layout>
);

export default AntiCrisisProgramWrapper;

import { ROUTES } from '@core/constants/routes';

import Photos from './photos/components/Photos';
import Video from './videoArchive/components/Video';

export enum EMediaBankType {
  PHOTOS,
  VIDEO,
}

export const options = [
  {
    label: 'Фотогалерея',
    value: EMediaBankType.PHOTOS,
    link: {
      href: ROUTES.PHOTOS,
      label: 'Все фотографии',
    },
    content: <Photos />,
  }, {
    label: 'Видео',
    value: EMediaBankType.VIDEO,
    link: {
      href: ROUTES.VIDEO_ARCHIVE,
      label: 'Все видео',
    },
    content: <Video />,
  },
];

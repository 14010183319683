import styled from 'styled-components';

import Share from '@core/assets/icons/share.svg';
import colors from '@core/constants/colors';

export const ShareButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
`;

export const ShareIcon = styled(Share)`
  color: ${colors.cadetBlue};

  &:hover {
    color: ${colors.chambrayBlue};
  }
`;

import EmailIcon from '@core/assets/icons/email.svg';
import OdnoklassnikiIcon from '@core/assets/icons/odnoklassniki.svg';
import TelegramIcon from '@core/assets/icons/telegram.svg';
import VKIcon from '@core/assets/icons/vk.svg';
import { SocialLinkConfig } from '@core/components/Share/types';

export const buildVideoLink = (id: number) => `${window.location.origin}${window.location.pathname}?videoId=${id}`;

export const getVideoSocialLinks = (id: number, name: string, description?: string | null): SocialLinkConfig[] => {
  const link = buildVideoLink(id);
  const shortDescription = description ? description.substring(0, 300) : '';

  return [
    {
      id: 1,
      Icon: <TelegramIcon />,
      href: `https://t.me/share/url?url=${link}&text=${name}`,
    },
    {
      id: 2,
      Icon: <EmailIcon />,
      href: `mailto:?subject=${name}&body=${shortDescription}...%0A%0A${link}`,
    },
    {
      id: 3,
      Icon: <VKIcon />,
      href: `https://vk.com/share.php?url=${link}&title=${name}`,
    },
    {
      id: 4,
      Icon: <OdnoklassnikiIcon />,
      href: `https://connect.ok.ru/offer?url=${link}&title=${name}`,
    },
  ];
};

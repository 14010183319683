import Image from 'next/image';
import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';

export const Container = styled.div`
  position: relative;
  padding: 30px 34px;
  background-color: #F6F8F9;
  border-radius: 12px;
  box-shadow: 0 20px 32px rgba(0, 41, 80, 0.12);
  z-index: 2;

  @media ${DEVICES.MOBILE} {
    margin: 0 -24px;
  }

  @media ${BREAKPOINTS.TABLET} {
    padding: 42px 32px;
  }
`;

export const OrganizationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 24px;
  margin-top: 24px;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    gap: 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    width: 891px;
    margin: 52px auto 0;
  }
`;

export const Organization = styled.a`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 30px;

  @media ${BREAKPOINTS.TABLET} {
    flex: 0 0 calc(50% - 32px);
  }
`;

export const OrganizationIcon = styled(Image)`
  width: 50px;
  min-width: 50px;
`;

export const OrganizationName = styled(Typography).attrs({ type: 'p1', color: 'riverBlue' })``;

import * as UI from './BannerBuildingFuture.styles';
import bannerImage from './assets/building_future_banner.png';

const BannerBuildingFuture = () => (
  <UI.Layout>
    <UI.BannerImg alt="Строим будущее" src={bannerImage} />
  </UI.Layout>
);

export default BannerBuildingFuture;

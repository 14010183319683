import { useRouter } from 'next/router';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useModal } from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import { IMaterialVideo } from '@core/models/media';
import { useAuthenticated } from '@core/services/Auth/hooks/useAuthenticated';

import { fetchVideoById, setVideoItem, selectVideoItem } from '../../store/video';

export const useVideoModal = () => {
  const dispatch = useDispatch();
  const router = useRouter();
  const modal = useModal();
  const { data: selectedVideo } = useSelector(selectVideoItem);
  const isAuth = useAuthenticated();

  const openVideoModal = useCallback((video: IMaterialVideo) => {
    dispatch(setVideoItem(video));
  }, [dispatch]);

  const closeVideoModal = useCallback(() => {
    dispatch(setVideoItem(null));
  }, [dispatch]);

  useEffect(() => {
    const videoId = Number(router.query.videoId);

    if (videoId) {
      dispatch(fetchVideoById({ isPublic: !isAuth, id: videoId }));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isAuth]);

  useEffect(() => {
    if (selectedVideo) {
      modal.open(EModalNames.VIDEO);
    } else {
      modal.close(EModalNames.VIDEO);
    }
  }, [modal, selectedVideo]);

  return {
    selectedVideo,
    openVideoModal,
    closeVideoModal,
  };
};

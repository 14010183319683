import { IFilesProps } from '@features/news/NewsPage/components/Files/types';

import * as UI from './Files.styles';
import FileLink from './components/FileLink';

const Files = (props: IFilesProps) => {
  const { filesIds, isPublic } = props;

  return (
    <UI.FilesContainer>
      <UI.Heading>
        Материалы:
      </UI.Heading>

      {filesIds.map((fileId) => (
        <FileLink
          fileId={fileId}
          isPublic={isPublic}
          key={fileId}
        />
      ))}
    </UI.FilesContainer>
  );
};

export default Files;

export enum ENewsImportance {
  DEFAULT = 'default',
  HIGH = 'high',
  TOP = 'top',
}

export const NewsImportance = {
  default: {
    name: ENewsImportance.DEFAULT,
    title: 'Обычная',
  },
  high: {
    name: ENewsImportance.HIGH,
    title: 'Важная',
  },
  top: {
    name: ENewsImportance.TOP,
    title: 'Карусель',
  },
};

import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Preloader } from '@eisgs/preloader';

import { ENewsImportance } from '@core/constants/newsImportance';
import { INews } from '@core/models/news';
import { format } from '@core/utils/date';

import MetaTypeIcon from '@features/landing/UI/MetaTypeIcon';
import { fetchLandingNewsNextPage, selectLandingNews } from '@features/landing/store/landingNews';

import * as UI from './NewsCards.styles';
import { DEFAULT_LIMIT } from './constants';
import { IDefaultNewsProps } from './types';

const DefaultNews = (props: IDefaultNewsProps) => {
  const { onNewsClick } = props;

  const { data: newsList, meta: { fetchLoading, fetchedAll, fetchError } } = useSelector(selectLandingNews);
  const dispatch = useDispatch();

  const handleNewsClick = (news: INews) => () => {
    onNewsClick(news);
  };

  const handleShowMoreClick = () => {
    dispatch(fetchLandingNewsNextPage({
      importance: ENewsImportance.DEFAULT,
      limit: DEFAULT_LIMIT,
      offset: newsList?.length,
    }));
  };

  if (!newsList) {
    return null;
  }

  return (
    <UI.Layout>
      <UI.NewsContainer>
        {newsList.map((news) => (
          <UI.News
            key={news.id}
            onClick={handleNewsClick(news)}
          >
            <UI.NewsDate>
              {format(news.createdAt, 'DD MMM YYYY')}
            </UI.NewsDate>

            <MetaTypeIcon
              filesIds={news.filesIds}
              videoIds={news.videoIds}
            />

            <UI.NewsTitle>
              {news.title}
            </UI.NewsTitle>
          </UI.News>
        ))}
      </UI.NewsContainer>

      {fetchLoading && (
        <Preloader
          size={72}
          styles={UI.PreloaderStyles}
        />
      )}

      {!fetchLoading && !fetchError && !fetchedAll && (
        <UI.ShowMoreButton onClick={handleShowMoreClick}>
          Показать ещё
        </UI.ShowMoreButton>
      )}
    </UI.Layout>
  );
};

export default memo(DefaultNews);

import MainFactorsCards from '@core/components/AntiCrisisProgramComponents/MainFactorsCards';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';

import * as UI from './MainFactors.styles';

const MainFactors = () => (
  <UI.Layout id={LANDING_ANCHORS_ROUTES.MAIN_FACTORS}>
    <MainFactorsCards />
  </UI.Layout>
);

export default MainFactors;

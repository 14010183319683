import styled from 'styled-components';

import { Button } from '@core/UI/Button';
import { Typography } from '@core/UI/Typography';
import DownloadSvgIcon from '@core/assets/icons/download.svg';
import colors from '@core/constants/colors';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';

import ArrowRight from '../../assets/arrowRight.svg';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    // TODO: вернуть стили, см. ERS-23311
    // justify-content: space-between;
    margin: 0 0 0 32px;
  }
`;

export const Heading = styled(Typography).attrs(({ type }: { type: string }) => ({ type }))<{ type: string }>`
  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 40px;
    line-height: 50px;
  }
`;

export const LandingDescription = styled(Typography).attrs({ type: 'h2', weight: 'normal' })`
  margin-top: 16px;
`;

export const Videos = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 1fr;
  grid-gap: 12px;
  margin-top: 20px;
  height: 100%;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: repeat(2, 1fr);

    // TODO: вернуть стили, см. ERS-23311
    // margin: 32px 0;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    grid-gap: 24px;
    margin-top: 40px;
  }
`;

export const DownloadProgramWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 32px;

  @media ${BREAKPOINTS.TABLET} {
    background-color: ${colors.aquaHaze};
    border-radius: 12px;
    justify-content: flex-start;
  }
`;

export const DownloadProgram = styled.a`
  display: flex;
  align-items: center;
  background-color: transparent;
  padding: 18px 0;
`;

export const DownloadIcon = styled(DownloadSvgIcon)`
  color: ${colors.chambrayBlue};
  margin-top: -5px;
  margin-right: 8px;
`;

export const LoginButton = styled(Button)`
  width: 100%;
  padding: 10px 24px;

  @media ${DEVICES.MOBILE} {
    margin-top: 24px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    width: fit-content;
  }
`;

export const ArrowRightIcon = styled(ArrowRight)`
  margin-left: 14px;
`;

export const VideoArhiveLink = styled(Button)`
  align-items: center;
  justify-content: center;
  gap: 10px;
  padding: 10px 24px;
  margin-top: 16px;

  @media ${BREAKPOINTS.DESKTOP} {
    margin-left: auto;
  }
`;

import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';
import { horizontalScrollStyles } from '@core/styles/scroll.styles';
import { hexToRGB } from '@core/utils/styles';

import { IToggleButtonGroupStylesProps } from './types';

const getStylesByVariant = (variant: IToggleButtonGroupStylesProps['variant']) => {
  switch (variant) {
    case 'line':
      return css`
        gap: 0px;
        background-color: ${colors.lightGrey};
      `;
    case 'card':
    default:
      return css`
        gap: 18px;
        ${horizontalScrollStyles};
      `;
  }
};

export const ToggleButtonGroup = styled.div<IToggleButtonGroupStylesProps>`
  display: flex;

  ${({ variant }) => getStylesByVariant(variant)}

  ${({ styles }) => styles}
`;

export const Options = styled.div`
  display: none;
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 0px;
  background-color: ${colors.white};
  box-shadow: 0px 2px 6px ${hexToRGB(colors.black, 0.1)};
  white-space: nowrap;
`;

export const Option = styled.div`
  padding: 15px 12px;

  &:hover {
    background-color: ${colors.aquaHaze};
  }
`;

export const toggleButtonStyles = css`
  position: relative;

  &:hover ${Options} {
    display: block;
  }
`;

// import domrf from '@features/landing/assets/icons/domrf.svg?url';
import { LINKS } from '@core/constants/links';

import government from '@features/landing/assets/icons/government.svg?url';
import nprSafeRoads from '@features/landing/assets/icons/npr-safe-roads.svg?url';
import nprTrunkPlan from '@features/landing/assets/icons/npr-trunk-plan.svg?url';
import nprUrbanEnvironment from '@features/landing/assets/icons/npr-urban-environment.svg?url';
import stroycomplex from '@features/landing/assets/icons/stroycomplex.svg?url';
import minstroy from '@features/landing/assets/images/minstroy.png';
import mintrance from '@features/landing/assets/images/mintrance.png';

type TOrganization = {
  icon: string;
  id: number;
  name: string;
  url: string;
  width: number;
  height: number;
};

export const organizations: TOrganization[] = [
  {
    icon: government,
    id: 0,
    name: 'Правительство Российской Федерации',
    url: LINKS.GOVERNMENT,
    width: 50,
    height: 41,
  },
  {
    icon: stroycomplex,
    id: 1,
    name: 'Стройкомплекс России',
    url: LINKS.STROYCOMPLEX,
    width: 50,
    height: 41,
  },
  {
    icon: minstroy,
    id: 2,
    name: 'Минстрой России',
    url: LINKS.MINSTROY,
    width: 50,
    height: 52,
  },
  {
    icon: mintrance,
    id: 3,
    name: 'Минтранс России',
    url: LINKS.MINTRANS,
    width: 50,
    height: 57,
  },
  // ERS-28560: Иконка ДОМ.РФ временно скрыта
  // {
  //   icon: domrf,
  //   id: 4,
  //   name: 'ДОМ.РФ',
  // },
  {
    icon: nprUrbanEnvironment,
    id: 5,
    name: 'Национальный проект «Жильё и городская среда»',
    url: LINKS.NP_URBAN_ENVIRONMENT,
    width: 50,
    height: 35,
  },
  {
    icon: nprSafeRoads,
    id: 6,
    name: 'Национальный проект «Безопасные качественные дороги»',
    url: LINKS.NP_SAFE_ROADS,
    width: 50,
    height: 35,
  },
  {
    icon: nprTrunkPlan,
    id: 7,
    name: 'Национальный проект «Магистральный план. Транспортная часть»',
    url: LINKS.NP_MAGISTRAL_PLAN,
    width: 50,
    height: 35,
  },
];

import styled from 'styled-components';

import { Input } from '@core/UI/Input';
import { BasedInput, InputWrapper } from '@core/UI/Input/Input.styles';
import colors from '@core/constants/colors';

export const Container = styled.div`
  min-width: 260px;
`;

export const Icons = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 12px;
  border-bottom: 1px solid ${colors.mystic};
`;

export const IconLink = styled.a`
  display: flex;
  cursor: pointer;
  color: ${colors.cadetBlue};

  & + & {
    margin-left: 20px;
  }

  &:hover {
    color: ${colors.chambrayBlue};
  }
`;

export const LinkInput = styled(Input)`
  padding: 12px 0 8px;

  ${InputWrapper} {
    padding: 0;
  }

  ${BasedInput} {
    padding: 8px 16px;
  }
`;

export const CopyLink = styled.button`
  background-color: transparent;
  border: none;
  padding: 0;
  margin: 0;
  cursor: pointer;
  color: ${colors.chambrayBlue};
  font-size: 14px;
`;

import { CameraIcon } from '@core/UI/Icons';
import { Typography } from '@core/UI/Typography';
import { formatDateShort } from '@core/utils/date';

import * as UI from './PrimaryAlbum.styles';
import { IPrimaryAlbumProps } from './types';

const PrimaryAlbum = (props: IPrimaryAlbumProps) => {
  const { album, onClick, styles } = props;
  const { name, createdAt, materials, tags } = album;

  return (
    <UI.PrimaryAlbumCard
      backgroundImageId={materials[0]?.file.id}
      styles={styles}
      onClick={() => onClick?.(album)}
    >
      <UI.Label>Альбом</UI.Label>
      <UI.Name>{name}</UI.Name>

      <UI.Meta>
        <Typography
          color="aquaHaze"
          type="p2"
        >
          {formatDateShort(createdAt)}
        </Typography>

        <UI.Count>
          <CameraIcon />
          <Typography
            color="aquaHaze"
            type="p2"
          >
            {`${materials.length} фото`}
          </Typography>
        </UI.Count>

        <UI.TagsContainer>
          {tags.map((tag) => (
            <UI.Tag key={tag.id}>
              <Typography
                color="white"
                type="p2"
              >
                {tag.name}
              </Typography>
            </UI.Tag>
          ))}
        </UI.TagsContainer>
      </UI.Meta>
    </UI.PrimaryAlbumCard>
  );
};

export default PrimaryAlbum;

import { Layout } from '@core/UI/Layout';
import RegulatoryWork from '@core/components/AntiCrisisProgramComponents/RegulatoryWork';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';

const RegulatoryWorkWrapper = () => (
  <Layout id={LANDING_ANCHORS_ROUTES.REGULATORY_WORK}>
    <RegulatoryWork isPublic />
  </Layout>
);

export default RegulatoryWorkWrapper;

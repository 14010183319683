import { memo } from 'react';

import DataProvider from '@core/components/DataProvider';
import { INews } from '@core/models/news';
import { format } from '@core/utils/date';
import { getPublicFileUrl } from '@core/utils/downloadFile';

import { selectLandingHighNews } from '@features/landing/store/landingHighNews';

import * as UI from './HighImportanceNews.styles';
import { IHighImportanceNewsProps } from './types';

const HighImportanceNews = (props: IHighImportanceNewsProps) => {
  const { onNewsClick } = props;

  const handleNewsClick = (news: INews) => {
    onNewsClick(news);
  };

  return (
    <DataProvider<INews[]>
      fetchErrorFallback={<></>}
      selector={selectLandingHighNews}
    >
      {({ data }) => {
        if (!data?.length) {
          return null;
        }

        return (
          <UI.Layout>
            <UI.NewsContainer>
              {data.map((news) => {
                const { imagesIds = [] } = news;
                const [backgroundId] = imagesIds;

                return (
                  <div key={news.id}>
                    <UI.News
                      onClick={() => { handleNewsClick(news); }}
                    >
                      <UI.Image alt={news.title} src={getPublicFileUrl(backgroundId)} />
                      <UI.NewsMeta>
                        <UI.NewsDate>
                          {format(news.createdAt, 'DD MMM YYYY')}
                        </UI.NewsDate>
                      </UI.NewsMeta>

                      <UI.NewsTitle>
                        {news.title}
                      </UI.NewsTitle>
                    </UI.News>

                    <UI.NewsText>
                      {news.text}
                    </UI.NewsText>

                    <UI.NewsMobile>
                      <UI.NewsMobileTitle>
                        {news.title}
                      </UI.NewsMobileTitle>

                      <UI.NewsMobileText>
                        {news.text}
                      </UI.NewsMobileText>
                    </UI.NewsMobile>
                  </div>
                );
              })}
            </UI.NewsContainer>
          </UI.Layout>
        );
      }}
    </DataProvider>
  );
};

export default memo(HighImportanceNews);

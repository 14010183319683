import { INews } from '@core/models/news';

import { IShareInfo } from '@features/landing/components/NewsModal/components/TooltipContent/types';

export const buildNewsLink = (news: INews) => `${window.location.origin}?news=${news.id}`;

export const buildNewsShareItem = (news: INews): IShareInfo => ({
  title: news.title,
  text: news.text.substring(0, 300),
  link: buildNewsLink(news),
});

import Link from 'next/link';
import styled, { css } from 'styled-components';

import ArrowRight from '@core/assets/icons/arrow_right.svg';
import colors from '@core/constants/colors';
import { BREAKPOINTS, PAGE_PADDINGS } from '@core/constants/styles';

export const Container = styled.div`
  padding: 24px 50px;
  background: ${colors.aquaHaze};
  width: calc(100% + ${PAGE_PADDINGS.MOBILE * 2}px);
  margin-left: -${PAGE_PADDINGS.MOBILE}px;
  display: flex;
  align-items: center;
  flex-direction: column;

  @media ${BREAKPOINTS.TABLET} {
    flex-direction: row;
    justify-content: space-between;
    padding: 17px;
    border-radius: 8px;
    width: 100%;
    margin-left: 0;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 17px 22px;
    justify-content: initial;
  }
`;

export const TitleStyles = css`
  font-weight: 700;
  font-size: 24px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
  cursor: pointer;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: 0;
    font-size: 28px;
    line-height: 42px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 32px;
  }
`;

export const InfoBlock = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    display: flex;
    align-items: center;
    margin-left: 34px;
  }
`;

export const IconStyles = css`
  cursor: pointer;
  margin-right: 5px;
`;

export const RedirectBlock = styled(Link)`
  display: none;

  @media ${BREAKPOINTS.DESKTOP_LG}{
    display: flex;
    align-items: center;
    gap: 5px;
    margin-left: auto;
    cursor: pointer;
  }
`;

export const ButtonArrowIcon = styled(ArrowRight).attrs({ color: colors.chambrayBlue })`
  width: 20px;
  height: 20px;
`;

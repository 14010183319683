import { Preloader } from '@eisgs/preloader';

import { Typography } from '@core/UI/Typography';

import * as UI from './ToggleButton.styles';
import { IToggleButtonProps } from './types';

const ToggleButton = (props: IToggleButtonProps) => {
  const {
    active,
    children,
    disabled,
    loading,
    onClick,
    variant,
    ...restProps
  } = props;

  const handleClick = (event: React.MouseEvent | React.TouchEvent) => {
    if (!loading && onClick) {
      onClick(event);
    }
  };

  return (
    <UI.ToggleButton
      {...restProps}
      active={active}
      disabled={disabled}
      loading={loading}
      type="button"
      variant={variant}
      onClick={handleClick}
    >
      <Typography
        color="inherit"
        tag="div"
        type="p2"
      >
        {children}
      </Typography>

      {loading && (
        <Preloader
          isAbsolute
          color="white"
          size={16}
          styles={UI.PreloaderStyles}
        />
      )}
    </UI.ToggleButton>
  );
};

export default ToggleButton;

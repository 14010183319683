import Link from 'next/link';
import React from 'react';

import { InfoOutlinedIcon } from '@eisgs/icon';
import { useModal } from '@eisgs/modal';
import { Typography } from '@eisgs/typography';

import { Layout } from '@core/UI/Layout';
import { EModalNames } from '@core/UI/Modal/constants';
import colors from '@core/constants/colors';
import { ROUTES } from '@core/constants/routes';

import EncyclopediaInfoModal from '@features/encyclopedia/components/EncyclopediaInfoModal';

import * as UI from './EncyclopediaRedirect.styles';

const EncyclopediaRedirect = () => {
  const modal = useModal();

  const handleOpenInfoModal = () => {
    modal.open(EModalNames.ENCYCLOPEDIA_INFO);
  };

  return (
    <Layout>
      <UI.Container>
        <Link
          href={ROUTES.ENCYCLOPEDIA}
        >
          <Typography
            styles={UI.TitleStyles}
            tag="span"
            type="p1"
          >
            Большая строительная энциклопедия
          </Typography>
        </Link>

        <UI.InfoBlock onClick={handleOpenInfoModal}>
          <InfoOutlinedIcon
            color={colors.chambrayBlue}
            size={24}
            styles={UI.IconStyles}
          />
          <Typography
            color={colors.chambrayBlue}
            type="p1"
          >
            О разделе
          </Typography>
        </UI.InfoBlock>

        <UI.RedirectBlock
          href={ROUTES.ENCYCLOPEDIA}
        >
          <Typography
            color={colors.chambrayBlue}
            tag="span"
            type="p1"
          >
            Перейти в раздел
          </Typography>
          <UI.ButtonArrowIcon />
        </UI.RedirectBlock>
      </UI.Container>

      <EncyclopediaInfoModal />
    </Layout>
  );
};

export default EncyclopediaRedirect;

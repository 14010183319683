import { ESortDirection } from '@core/UI/Table/types';
import { fetchLegalDocuments } from '@core/components/AntiCrisisProgramComponents/RegulatoryWork/components/LegalDocuments/store';
import { fetchStudyMaterials } from '@core/components/AntiCrisisProgramComponents/RegulatoryWork/components/StudyMaterials/store';
import Template from '@core/components/Template';
import { ENewsImportance } from '@core/constants/newsImportance';
import { EPlaceholderIds } from '@core/constants/placeholders';
import { wrapper } from '@core/store';
import { EFileType } from '@core/store/modules/materials/types';
import { fetchPlaceholdersByIds } from '@core/store/modules/placeholders/store';

import Landing from '@features/landing';
import { DEFAULT_LIMIT as DEFAULT_LIMIT_LANDING_NEWS } from '@features/landing/components/DefaultNews/constants';
import { fetchLandingHighNews } from '@features/landing/store/landingHighNews';
import { fetchHousingCommissioning } from '@features/landing/store/landingHousingCommissioning';
import { fetchLandingNews } from '@features/landing/store/landingNews';
import { fetchLandingTopNews } from '@features/landing/store/landingTopNews';
import { fetchAlbums } from '@features/media-bank/photos/store/albums';
import { fetchVideoArchive } from '@features/media-bank/videoArchive/store/videoArchive';

const LandingPage = () => (
  <Template isPublic>
    <Landing />
  </Template>
);

export const getServerSideProps = wrapper.getServerSideProps(({ dispatch }) => async () => {
  await Promise.all([
    dispatch(fetchLandingTopNews({ importance: ENewsImportance.TOP })),
    dispatch(fetchLandingHighNews({ importance: ENewsImportance.HIGH })),
    dispatch(fetchLandingNews({ importance: ENewsImportance.DEFAULT, limit: DEFAULT_LIMIT_LANDING_NEWS, offset: 0 })),
    dispatch(fetchStudyMaterials({ sortingField: 'createdAt', sortingDirection: ESortDirection.ASC, isPublic: true })),
    dispatch(fetchLegalDocuments({
      type: EFileType.FEDERAL_LAW,
      sortingField: 'createdAt',
      sortingDirection: ESortDirection.DESC,
      isPublic: true,
    })),
    dispatch(fetchHousingCommissioning()),
    dispatch(fetchAlbums({ limit: 5, offset: 0 })),
    dispatch(fetchVideoArchive({ isPublic: true, limit: 5, offset: 0 })),
    dispatch(fetchPlaceholdersByIds([
      EPlaceholderIds.ANTI_CRISIS_PREVIEW_1,
      EPlaceholderIds.ANTI_CRISIS_VIDEO_1,
      EPlaceholderIds.ANTI_CRISIS_PREVIEW_2,
      EPlaceholderIds.ANTI_CRISIS_VIDEO_2,
    ])),
  ]);

  return { props: {} };
});

export default LandingPage;

import styled, { css } from 'styled-components';

import { Button } from '@core/UI/Button';
import { Layout as LayoutUI } from '@core/UI/Layout';
import colors from '@core/constants/colors';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';

export const Layout = styled(LayoutUI)`
  position: relative;

  @media ${DEVICES.MOBILE} {
    padding: 0;
  }
`;

export const Content = styled.div<{ shown: boolean }>`
  position: relative;

  @media ${DEVICES.MOBILE} {
    margin-top: 24px;
    max-height: 700px;
    overflow: hidden;
    border-radius: 8px;

    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: linear-gradient(transparent 60%, ${colors.aquaHaze});
    }

    ${({ shown }) => shown && css`
      max-height: unset;

      &::after {
        background: unset;
      }
    `}
  }

  @media ${BREAKPOINTS.TABLET} {
    margin-top: 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 40px;
  }
`;

export const ShowMoreContainer = styled.div`
  position: absolute;
  bottom: 35px;
  width: 100%;
  z-index: 1;
`;

export const ShowMoreButton = styled(Button).attrs({ type: 'secondary' })`
  margin: 24px auto 0;
`;

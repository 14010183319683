import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import SectionHeader from '@core/components/LandingSectionHeader';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';
import { useAppDispatch } from '@core/store';
import { format } from '@core/utils/date';

import DynamicTable from '@features/dynamicTable';
import {
  resetState,
  selectHousingCommissioning,
} from '@features/landing/store/landingHousingCommissioning';

import * as UI from './HousingCommissioning.styles';

const HousingCommissioning = () => {
  const isTablet = useMedia(BREAKPOINTS.TABLET);
  const [isOpen, setIsOpen] = useState<boolean>(isTablet);

  const dispatch = useAppDispatch();
  const { data, meta } = useSelector(selectHousingCommissioning);

  useEffect(() => () => {
    dispatch(resetState({}));
  }, [dispatch]);

  return (
    <UI.Layout id={LANDING_ANCHORS_ROUTES.HOUSING_COMMISSIONING}>
      <SectionHeader>
        Ввод жилья на
        {' '}
        {format(data?.date, 'DD.MM.YYYY')}
      </SectionHeader>

      <UI.Content shown={isOpen}>
        <DynamicTable
          data={data}
          meta={meta}
        />

        {!isTablet && !isOpen && (
          <UI.ShowMoreContainer>
            <UI.ShowMoreButton onClick={() => setIsOpen(true)}>
              Показать все
            </UI.ShowMoreButton>
          </UI.ShowMoreContainer>
        )}
      </UI.Content>
    </UI.Layout>
  );
};

export default HousingCommissioning;

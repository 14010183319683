import styled from 'styled-components';

import DownloadSvgIcon from '@core/assets/icons/download.svg';
import colors from '@core/constants/colors';

export const DownloadIcon = styled(DownloadSvgIcon)`
  margin-top: -5px;
  margin-right: 8px;
  color: ${colors.chambrayBlue};
  flex-shrink: 0;
`;

export const FileLink = styled.a`
  display: flex;
  align-items: center;
  color: ${colors.manatee};
  overflow: hidden;
  max-width: 100%;
  word-break: break-word;
`;

import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import Card from '@features/media-bank/common/Card';

export const PrimaryAlbumCard = styled(Card)`
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 32px 24px;
  }

  ${Typography} {
    cursor: default;
  }
`;

export const Label = styled(Typography).attrs({ type: 'p1', color: 'aquaHaze' })`
  font-size: 10px;
  text-transform: uppercase;
`;

export const Name = styled(Typography).attrs({ type: 'h2', color: 'white', ellipsisMaxLines: 3 })`
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 8px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 32px;
    line-height: 42px;
    margin-bottom: 16px;
  }
`;

export const Meta = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: auto 1fr;
  gap: 16px 24px;

  @media ${BREAKPOINTS.TABLET} {
    grid-template-columns: repeat(3,max-content);
    gap: 24px;
  }
`;

export const Count = styled.div`
  display: flex;
  gap: 10px;

  svg {
    margin-top: 1px;
  }
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 8px;
  grid-column: span 2;

  @media ${BREAKPOINTS.TABLET} {
    grid-column: auto;
  }
`;

export const Tag = styled.div`
  padding: 7px;
  border: 1px solid ${colors.softGrey};
  border-radius: 4px;
`;

import styled from 'styled-components';

import { Layout as LayoutUI } from '@core/UI/Layout';
import { DEVICES } from '@core/constants/styles';

export const Layout = styled(LayoutUI)`
  @media ${DEVICES.TABLET} {
    padding-top: 0;
  }
`;

import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';
import { hexToRGB } from '@core/utils/styles';

import { Typography } from '../Typography';

import { ITagStylesProps, TSize, TVariant } from './types';

const getSize = (size: TSize) => {
  switch (size) {
    case 'medium':
      return css`
        padding: 8px;
      `;
    case 'small':
    default:
      return css`
        padding: 4px;
      `;
  }
};

const getVariantStyles = (variant: TVariant) => {
  switch (variant) {
    case 'outline':
      return css`
        border: 1px solid ${colors.softGrey};
        background-color: transparent;
      `;
    case 'default':
    default:
      return css`
        background-color: ${hexToRGB(colors.white, 0.6)};
      `;
  }
};

export const Tag = styled(Typography)<ITagStylesProps>`
  display: inline-block;
  text-align: center;
  border-radius: 4px;

  ${({ size }) => getSize(size)}
  ${({ variant }) => getVariantStyles(variant)}
`;

import SectionHeader from '@features/landing/UI/SectionHeader';

import * as UI from './Organizations.styles';
import { organizations } from './data';

const OrganizationsSection = () => (
  <UI.Container>
    <SectionHeader>
      Ведомства, организации и проекты
    </SectionHeader>

    <UI.OrganizationsContainer>
      {organizations.map((organization) => (
        <UI.Organization
          href={organization.url}
          key={organization.id}
          rel="noopener noreferrer"
          target="_blank"
        >
          <UI.OrganizationIcon
            alt={organization.name}
            height={organization.height}
            src={organization.icon}
            width={organization.width}
          />

          <UI.OrganizationName>
            {organization.name}
          </UI.OrganizationName>
        </UI.Organization>
      ))}
    </UI.OrganizationsContainer>
  </UI.Container>
);

export default OrganizationsSection;

// TODO: вернуть отображение кнопки, см. ERS-23311
// import { useRouter } from 'next/router';
import { useState } from 'react';

import { PlayOutlineIcon } from '@core/UI/Icons';
import { useModal } from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import { Typography } from '@core/UI/Typography';
// TODO: вернуть отображение кнопки, см. ERS-23311
// import { ROUTES } from '@core/constants/routes';
import { ROUTES } from '@core/constants/routes';
import { EStaticFilesIds } from '@core/constants/staticFiles';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';
import { useVideosAntiCrisisProgram } from '@core/hooks/useVideosAntiCrisisProgram';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import VideoModal from '@features/strategy/components/AntiCrisisProgram/components/VideoModal';

import Video from '../Video';

import * as UI from './Program.styles';
import { IProgramProps } from './types';

const AntiCrisisProgram = (props: IProgramProps) => {
  const { variant } = props;

  // TODO: вернуть отображение кнопки, см. ERS-23311
  // const router = useRouter();
  const isTablet = useMedia(BREAKPOINTS.TABLET);
  const modal = useModal();
  const [videoId, setVideoId] = useState<number | null>(null);
  const videos = useVideosAntiCrisisProgram();

  const openModal = (id: number) => {
    setVideoId(id);
    modal.open(EModalNames.ANTI_CRISIS_VIDEO);
  };

  // TODO: вернуть отображение кнопки, см. ERS-23311
  // const login = () => {
  //   router.push(ROUTES.LOGIN);
  // };

  return (
    <>
      <UI.Container>
        <UI.Heading type={isTablet ? 'h1' : 'h2'}>
          Портал строительного комплекса
          Российской Федерации
        </UI.Heading>

        {variant === 'landing' && (
          <UI.LandingDescription>
            Единое информационное пространство — инструмент мониторинга достижения национальной
            цели &quot;Комфортная и безопасная среда для жизни&quot;
          </UI.LandingDescription>
        )}

        <UI.Videos>
          {videos.map(({ preview, visible, video, id }) => visible && (
            <Video
              imgPreview={getPublicFileUrl(preview)}
              key={id}
              openModal={() => openModal(video)}
            />
          ))}
        </UI.Videos>

        {variant === 'regular' && (
          <UI.DownloadProgramWrapper>
            <UI.DownloadProgram href={getFileUrl(EStaticFilesIds.ANTI_CRISIS_PROGRAM)}>
              <UI.DownloadIcon />

              <Typography color="riverBlue" type="p2">
                Скачать антикризисную программу
              </Typography>
            </UI.DownloadProgram>
          </UI.DownloadProgramWrapper>
        )}

        {variant === 'landing' && (
          <UI.VideoArhiveLink href={ROUTES.VIDEO_ARCHIVE}>
            <PlayOutlineIcon />
            Видеохроника стройкомплекса
          </UI.VideoArhiveLink>
        )}

        {/* TODO: вернуть отображение кнопки, см. ERS-23311 */}
        {/* {variant === 'landing' && ( */}
        {/*  <UI.LoginButton onClick={login}> */}
        {/*    Антикризисная программа */}
        {/*    <UI.ArrowRightIcon /> */}
        {/*  </UI.LoginButton> */}
        {/* )} */}
      </UI.Container>

      {videoId && <VideoModal isPublic={variant === 'landing'} videoId={videoId} />}
    </>
  );
};

export default AntiCrisisProgram;

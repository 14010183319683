import styled from 'styled-components';

import { Layout as LayoutUI } from '@core/UI/Layout';
import { BREAKPOINTS } from '@core/constants/styles';

export const Layout = styled(LayoutUI)`
  padding-top: 24px;

  @media ${BREAKPOINTS.DESKTOP} {
    padding-top: 94px;
  }
`;

import { buildNewsLink } from '@features/landing/components/NewsModal/components/TooltipContent/helpers';

import * as UI from './TooltipContent.styles';
import { data } from './data';
import { ITooltipContentProps } from './types';

const TooltipContent = (props: ITooltipContentProps) => {
  const { news } = props;
  const newsLink = buildNewsLink(news);

  const copyUrlToClipboard = () => {
    navigator.clipboard?.writeText(buildNewsLink(news));
  };

  return (
    <UI.Container>
      <UI.Icons>
        {data.map(({ id, Icon, buildHref }) => (
          <UI.IconLink href={buildHref(news)} key={id} target="_blank">
            {Icon}
          </UI.IconLink>
        ))}
      </UI.Icons>

      <UI.LinkInput
        value={newsLink}
      />

      <UI.CopyLink onClick={copyUrlToClipboard}>
        Скопировать ссылку
      </UI.CopyLink>
    </UI.Container>
  );
};

export default TooltipContent;

import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media ${BREAKPOINTS.DESKTOP} {
    gap: 25px;
  }
`;

export const VideoList = styled.div`
  display: flex;
  gap: 15px;
  overflow-y: auto;

  @media ${BREAKPOINTS.DESKTOP} {
    gap: 25px;
  }
`;

export const EmptyMessage = styled(Typography).attrs({ type: 'h1' })`
  margin-top: 10px;
  text-align: center;
`;

import styled from 'styled-components';

import colors from '@core/constants/colors';

export const Preview = styled.div`
  position: relative;
  overflow: hidden;
  border-radius: 12px;
  cursor: pointer;
  min-height: 255px;

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  svg {
    position: absolute;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    color: ${colors.white};
  }
`;

import { useEffect, useState } from 'react';

import http from '@core/services/Http';
import { IFileInfo } from '@core/store/modules/materials/types';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import * as UI from './FileLink.styles';
import { IFileLinkProps } from './types';

const FileLink = (props: IFileLinkProps) => {
  const { fileId, isPublic } = props;
  const [fileName, setFileName] = useState<string | null>();
  const fileHref = isPublic ? getPublicFileUrl(fileId) : getFileUrl(fileId);

  useEffect(() => {
    const getFileMeta = async (id: number) => {
      try {
        const url = isPublic ? `/file-public/${id}` : `/file/${id}`;
        const { data } = await http.get<IFileInfo>(url);
        setFileName(data.title || data.name);
      } catch (e) {
        setFileName(null);
      }
    };

    getFileMeta(fileId);
  }, [fileId, isPublic]);

  return fileName ? (
    <UI.FileLink href={fileHref}>
      <UI.DownloadIcon />
      {fileName}
    </UI.FileLink>
  ) : null;
};

export default FileLink;

import { useRouter } from 'next/router';
import { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

import { useModal } from '@eisgs/modal';

import { EModalNames } from '@core/UI/Modal/constants';
import { INews } from '@core/models/news';
import { useAppDispatch } from '@core/store';

import DefaultNews from '@features/landing/components/DefaultNews';
import HighImportanceNews from '@features/landing/components/HighImportanceNews';
import NewsModal from '@features/landing/components/NewsModal';
import TopNews from '@features/landing/components/TopNews';
import { fetchPublicNews, selectPublicNewsItem } from '@features/landing/store/publicNewsModal';

const News = () => {
  const modal = useModal();
  const dispatch = useAppDispatch();
  const { query } = useRouter();
  const queryNewsId = Number(query.news);
  const { data: queryNews } = useSelector(selectPublicNewsItem);

  const [activeNews, setActiveNews] = useState<INews | null>(null);

  useEffect(() => {
    if (!Number.isNaN(queryNewsId)) {
      dispatch(fetchPublicNews(queryNewsId));
    }
  }, [dispatch, queryNewsId]);

  const openNewsModal = useCallback((news: INews) => {
    setActiveNews(news);
    modal.open(EModalNames.LANDING_NEWS);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (queryNews !== null) {
      openNewsModal(queryNews);
    }
  }, [openNewsModal, queryNews]);

  const handleModalClose = () => {
    setActiveNews(null);
    modal.close(EModalNames.LANDING_NEWS);
  };

  return (
    <>
      <TopNews onNewsClick={openNewsModal} />
      <HighImportanceNews onNewsClick={openNewsModal} />
      <DefaultNews onNewsClick={openNewsModal} />

      {activeNews && (
        <NewsModal
          news={activeNews}
          onClose={handleModalClose}
        />
      )}
    </>
  );
};

export default News;

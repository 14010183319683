import ImageNext from 'next/image';
import styled from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const SlideContainer = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  width: 100%;
  height: 192px;
  padding: 16px 10px;
  border-radius: 12px;
  background-size: cover;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 41, 80, 0.4);
    border-radius: 12px;
  }

  @media ${BREAKPOINTS.TABLET} {
    height: 294px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    justify-content: space-between;
    gap: 0 32px;
    height: 621px;
    padding: 16px 32px 28px 42px;
  }
`;

export const Image = styled(ImageNext).attrs({ fill: true })`
  z-index: -1;
  object-fit: cover;
`;

export const SlideMeta = styled.div`
  z-index: 1;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 16px 20px;
    background: rgba(0, 0, 0, 0.2);
    backdrop-filter: blur(24px);
    border-radius: 8px;
  }
`;

export const SlideHeader = styled(Typography).attrs({ type: 'h2', color: 'white' })`
  z-index: 1;
`;

export const SlideDate = styled(Typography).attrs({ type: 'p2', color: 'white' })``;

export const SlideMetaType = styled.div`
  @media ${BREAKPOINTS.DESKTOP_LG} {
    margin-top: 20px;
  }
`;

export const MobileHeader = styled(Typography).attrs({ type: 'p1', color: 'chambrayBlue', weight: 'bold' })`
  margin-top: 10px;
  text-align: left;
`;

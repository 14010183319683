import { memo } from 'react';
import { SwiperSlide } from 'swiper/react';

import DataProvider from '@core/components/DataProvider';
import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';
import { INews } from '@core/models/news';

import CarouselSlide from '@features/landing/components/CarouselSlide';
import { selectLandingTopNews } from '@features/landing/store/landingTopNews';

import * as UI from './Topnews.styles';
import CarouselNews from './components/CarouselNews';
import { ITopNewsProps } from './types';

const TopNews = (props: ITopNewsProps) => {
  const { onNewsClick } = props;

  return (
    <DataProvider<INews[]>
      fetchErrorFallback={<></>}
      selector={selectLandingTopNews}
    >
      {({ data }) => {
        if (!data) {
          return null;
        }

        return (
          <UI.Layout>
            <UI.CarouselContainer id={LANDING_ANCHORS_ROUTES.NEWS}>
              <CarouselNews>
                {data?.map((news) => (
                  <SwiperSlide key={news.id}>
                    <CarouselSlide news={news} onNewsClick={onNewsClick} />
                  </SwiperSlide>
                ))}
              </CarouselNews>
            </UI.CarouselContainer>
          </UI.Layout>
        );
      }}
    </DataProvider>
  );
};

export default memo(TopNews);

import { MoreHorizIcon } from '@eisgs/icon';

import ToggleButton from '@core/UI/ToggleButton';
import { IToggleButtonGroupProps } from '@core/UI/ToggleButtonGroup/types';

import { Typography } from '../Typography';

import * as UI from './ToggleButtonGroup.styles';

const ToggleButtonGroup = <T extends unknown>(props: IToggleButtonGroupProps<T>) => {
  const { options, value, onChange, color, className, styles, variant, tabMoreOptions } = props;
  const hasMoreOptions = tabMoreOptions && tabMoreOptions.length > 0;

  const handleChangeOption = (newValue: T) => () => {
    onChange(newValue);
  };

  return (
    <UI.ToggleButtonGroup className={className} styles={styles} variant={variant}>
      {options.map((option) => (
        <ToggleButton
          active={option.value === value}
          color={color}
          key={option.label}
          styles={option.styles}
          variant={variant}
          onClick={handleChangeOption(option.value)}
        >
          {option.label}
        </ToggleButton>
      ))}
      {hasMoreOptions && (
        <ToggleButton styles={UI.toggleButtonStyles} variant={variant}>
          <MoreHorizIcon />
          <UI.Options>
            {tabMoreOptions.map((option) => (
              <UI.Option
                key={option.label}
                onClick={handleChangeOption(option.value)}
              >
                <Typography tag="span" type="p1">{option.label}</Typography>
              </UI.Option>
            ))}
          </UI.Options>
        </ToggleButton>
      )}
    </UI.ToggleButtonGroup>
  );
};

export default ToggleButtonGroup;

import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS, DEVICES } from '@core/constants/styles';

export const modalStyles = css`
  width: 100vw;
  max-width: 100vw;
  border-radius: 8px;

  @media ${BREAKPOINTS.TABLET} {
    width: 704px;
    max-width: none;
  }
`;

export const NewsTitle = styled(Typography).attrs({ type: 'h3', color: 'riverBlue' })`
  padding-right: 40px;

  @media ${BREAKPOINTS.TABLET} {
    font-size: 24px;
    line-height: 32px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;

  @media ${DEVICES.MOBILE} {
    justify-content: space-between;
  }
`;

export const NewsDate = styled(Typography).attrs({ type: 'p2', color: 'cadetBlue' })`
  margin-right: 12px;
`;

export const newsCarouselStyles = css`
  margin-top: 16px;
`;

export const NewsText = styled(Typography).attrs({ type: 'p1' })`
  margin-top: 16px;
  white-space: pre-line;
`;

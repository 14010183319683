import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Modal from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import Tag from '@core/UI/Tag';
import { Typography } from '@core/UI/Typography';
import Share from '@core/components/Share';
import VideoPlayer from '@core/components/VideoPlayer';

import * as UI from './VideoModal.styles';
import { buildVideoLink, getVideoSocialLinks } from './socials';
import { IVideoModalProps } from './types';

const VideoModal = (props: IVideoModalProps) => {
  const { id, name, description, date, tags, videoUrl, onClose } = props;

  const router = useRouter();

  useEffect(() => {
    // Проверяем готовность роута - Cancel Rendering Route [https://github.com/vercel/next.js/issues/37362]
    if (router.isReady) {
      return;
    }
    router.replace(`?videoId=${id}`, undefined, { shallow: true });

    return () => {
      router.replace(router.pathname, undefined, { shallow: true });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  return (
    <Modal
      name={EModalNames.VIDEO}
      styles={UI.modalStyles}
      onClose={onClose}
    >
      <UI.Title>{name}</UI.Title>
      <UI.Description>{description}</UI.Description>
      <UI.Wrapper>
        <Typography type="p2">{date}</Typography>
        <Share link={buildVideoLink(id)} socials={getVideoSocialLinks(id, name, description)} />
        {tags && (
          <UI.TagGroup>
            {tags.map((tag) => <Tag key={tag.id} size="medium" variant="outline">{tag.name}</Tag>)}
          </UI.TagGroup>
        )}
      </UI.Wrapper>
      <VideoPlayer controls styles={UI.videoStyles} url={videoUrl} />
    </Modal>
  );
};

export default VideoModal;

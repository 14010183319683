import ImageNext from 'next/image';
import styled, { css } from 'styled-components';

import VideoPlayer from '@core/components/VideoPlayer';
import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

import { ISwiperWrapperStylesProps } from './types';

export const SwiperWrapper = styled.div<ISwiperWrapperStylesProps>`
  .swiper {
    height: 200px;

    @media ${BREAKPOINTS.TABLET} {
      height: 320px;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      height: 400px;
    }
  }

  .swiper-pagination-bullets {
    display: flex;
    justify-content: center;
    margin-top: 12px;

    .swiper-pagination-bullet {
      width: 10px;
      height: 10px;
      background-color: ${colors.cadetBlue};
    }

    .swiper-pagination-bullet-active {
      background-color: ${colors.chambrayBlue};
    }
  }

  ${({ styles }) => styles};
`;

export const NavigationButton = styled.button<{ isNext?: boolean }>`
  visibility: hidden;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid ${colors.manatee};
  border-radius: 50%;
  background-color: rgba(255,255,255, 0.7);
  color: ${colors.manatee};
  cursor: pointer;

  position: absolute;
  top: calc(50% - 40px / 2);
  z-index: 1;

  ${({ isNext }) => (isNext ? css`
    right: 16px;
  ` : css`
    left: 16px;

    svg {
      transform: rotate(180deg);
    }
  `)}

  &.swiper-button-lock {
    display: none;
  }

  @media ${BREAKPOINTS.TABLET} {
    visibility: visible;
  }
`;

export const Image = styled(ImageNext).attrs({ fill: true })`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const Video = styled(VideoPlayer)`
  width: 100%;
  height: 100%;
  background: linear-gradient(#173e63, #0d2438);
`;

import { useRouter } from 'next/router';
import { useEffect } from 'react';

import Modal from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import NewsCarousel from '@core/components/NewsCarousel';
import { format } from '@core/utils/date';

import Files from '@features/news/NewsPage/components/Files';

import * as UI from './NewsModal.styles';
import Share from './components/Share';
import { INewsModalProps } from './types';

const NewsModal = (props: INewsModalProps) => {
  const {
    news,
    onClose,
  } = props;
  const { imagesIds, videoIds } = news;
  const hasFiles = Array.isArray(news.filesIds) && news.filesIds.length !== 0;
  const router = useRouter();

  useEffect(() => {
    // Проверяем готовность роута - Cancel Rendering Route [https://github.com/vercel/next.js/issues/37362]
    if (!router.isReady) {
      return;
    }

    router.replace(`?news=${news.id}`, undefined, { shallow: true });

    return () => {
      router.replace('/', undefined, { shallow: true });
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [news.id]);

  return (
    <Modal
      unblockOnUnmount
      name={EModalNames.LANDING_NEWS}
      styles={UI.modalStyles}
      onClose={onClose}
    >
      <UI.NewsTitle>
        {news.title}
      </UI.NewsTitle>

      <UI.DateContainer>
        <UI.NewsDate>
          {format(news.createdAt, 'DD MMM YYYY')}
        </UI.NewsDate>

        <Share news={news} />
      </UI.DateContainer>

      <NewsCarousel
        isPublic
        imagesIds={imagesIds}
        styles={UI.newsCarouselStyles}
        videoIds={videoIds}
      />

      <UI.NewsText>
        {news.text}
      </UI.NewsText>

      {hasFiles && (
        <Files
          isPublic
          filesIds={news.filesIds}
        />
      )}
    </Modal>
  );
};

export default NewsModal;

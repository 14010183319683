import styled from 'styled-components';

import colors from '@core/constants/colors';

import { IContainerStylesProps } from './types';

export const Container = styled.div<IContainerStylesProps>`
  height: 32px;

  svg * {
    stroke: ${({ $color }) => colors[$color]};
  }
`;

import { useRef, useState } from 'react';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import ArrowSlider from '@core/assets/icons/arrow_slider.svg';
import { getFileUrl, getPublicFileUrl } from '@core/utils/downloadFile';

import * as UI from './Slider.styles';
import { INewsCarouselProps } from './types';

import 'swiper/css';
import 'swiper/css/pagination';

const NewsCarousel = (props: INewsCarouselProps) => {
  const {
    isPublic = false,
    videoIds,
    imagesIds,
    styles,
  } = props;
  const hasImages = Array.isArray(imagesIds) && imagesIds.length !== 0;
  const hasVideos = Array.isArray(videoIds) && videoIds.length !== 0;

  const paginationRef = useRef<HTMLDivElement>(null);

  const [prevEl, setPrevEl] = useState<HTMLButtonElement | null>(null);
  const [nextEl, setNextEl] = useState<HTMLElement | null>(null);

  const getSlideFileUrl = (fileId: number) => {
    if (isPublic) {
      return getPublicFileUrl(fileId);
    }

    return getFileUrl(fileId);
  };

  if (!hasImages && !hasVideos) {
    return null;
  }

  return (
    <UI.SwiperWrapper styles={styles}>
      <Swiper
        loop={imagesIds.length > 1}
        modules={[Navigation, Pagination]}
        navigation={{ prevEl, nextEl }}
        pagination={{
          el: paginationRef.current,
          clickable: true,
          renderBullet: (index, className) => `<span class="${className}"></span>`,
        }}
      >
        {hasImages && imagesIds.map((imageId) => (
          <SwiperSlide key={imageId}>
            <UI.Image alt="" src={getSlideFileUrl(imageId)} />
          </SwiperSlide>
        ))}

        {hasVideos && videoIds.map((videoId) => (
          <SwiperSlide key={videoId}>
            <UI.Video controls url={getSlideFileUrl(videoId)} />
          </SwiperSlide>
        ))}

        <UI.NavigationButton
          ref={(node) => setPrevEl(node)}
          type="button"
        >
          <ArrowSlider />
        </UI.NavigationButton>

        <UI.NavigationButton
          isNext
          ref={(node) => setNextEl(node)}
          type="button"
        >
          <ArrowSlider />
        </UI.NavigationButton>
      </Swiper>

      <div ref={paginationRef} />
    </UI.SwiperWrapper>
  );
};

export default NewsCarousel;

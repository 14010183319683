import { useState } from 'react';
import { Autoplay, EffectCoverflow, Pagination, SwiperOptions } from 'swiper';
import { Swiper } from 'swiper/react';
import { Swiper as SwiperClass } from 'swiper/types';

import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';

import * as UI from './CarouselNews.styles';
import { ICarouselNewsProps } from './types';

import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

const commonSwiperProps: SwiperOptions = {
  pagination: {
    clickable: true,
    type: 'bullets',
  },
  autoplay: {
    delay: 15000,
    disableOnInteraction: true,
  },
  slidesPerView: 'auto',
  spaceBetween: 24,
};

const CarouselNews = (props: ICarouselNewsProps) => {
  const { children } = props;
  const isDesktop = useMedia(BREAKPOINTS.DESKTOP_LG, true);
  const [swiper, setSwiper] = useState<SwiperClass | null>(null);

  const slideBack = () => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex - 1);
    }
  };

  const slideForward = () => {
    if (swiper) {
      swiper.slideTo(swiper.activeIndex + 1);
    }
  };

  if (isDesktop) {
    return (
      <>
        <UI.ArrowLeft onClick={slideBack} />

        <UI.ArrowRight onClick={slideForward} />

        <Swiper
          {...commonSwiperProps}
          centeredSlides
          allowTouchMove={false}
          coverflowEffect={{
            rotate: 0,
            stretch: 0,
            depth: 100,
            modifier: 1,
            scale: 0.25,
            slideShadows: false,
          }}
          effect="coverflow"
          modules={[Autoplay, EffectCoverflow, Pagination]}
          spaceBetween={-808}
          onSwiper={setSwiper}
        >
          {children}
        </Swiper>
      </>
    );
  }

  return (
    <Swiper
      {...commonSwiperProps}
      breakpoints={{
        768: {
          spaceBetween: 40,
        },
      }}
      modules={[Autoplay, Pagination]}
    >
      {children}
    </Swiper>
  );
};

export default CarouselNews;

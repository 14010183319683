import Link from 'next/link';
import styled, { css } from 'styled-components';

import colors from '@core/constants/colors';
import { BREAKPOINTS } from '@core/constants/styles';

export const AllPhotosLink = styled(Link)`
  font-size: 13px;
  line-height: 24px;
  text-transform: uppercase;
  letter-spacing: 0.12em;
  color: ${colors.chambrayBlue};
`;

export const AlbumsContainer = styled.div`
  display: grid;
  gap: 16px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    grid-template-columns: 3fr 1fr;
    gap: 24px;
  }
`;

export const PrimaryAlbumStyles = css`
  height: 342px;

  @media ${BREAKPOINTS.TABLET} {
    height: 418px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    height: 741px;

    &:only-child {
      grid-column: span 2;
    }
  }
`;

export const SecondaryAlbumsWrapper = styled.div`
  overflow-x: auto;
`;

export const SecondaryAlbums = styled.div<{ count: number }>`
  display: grid;
  height: 148px;
  grid-template-columns: repeat(${({ count }) => count}, calc((100% - 16px) / 1.6));
  gap: 16px;

  @media ${BREAKPOINTS.TABLET} {
    height: 209px;
    grid-template-columns: repeat(${({ count }) => count}, calc((100% - 32px) / 3));
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    height: 100%;
    grid-template-columns: auto;
    grid-template-rows: repeat(3, 1fr);
    gap: 24px;
  }
`;

export const SecondaryAlbumStyles = css`
  flex-basis: 231px;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    &:nth-child(n + 4) {
      display: none;
    }
  }
`;

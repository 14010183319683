import styled, { css } from 'styled-components';

import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';
import { thickScrollStyles } from '@core/styles/scroll.styles';

export const modalStyles = css`
  display: flex;
  flex-direction: column;
  gap: 16px;
  top: 50%;
  transform: translate(0px, -50%);
  max-width: 920px;
  margin: 0px 20px;
  border-radius: 8px;
  max-height: 90vh;
  overflow-y: auto;
`;

export const Title = styled(Typography).attrs({ type: 'h3' })`
  @media ${BREAKPOINTS.TABLET} {
    font-size: 28px;
    line-height: 36px;
  }
`;

export const Description = styled(Typography).attrs({ type: 'p2' })`
  @media ${BREAKPOINTS.TABLET} {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 25px;
`;

export const TagGroup = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 8px;

  ${Typography} {
    white-space: nowrap;
  }

  @media ${BREAKPOINTS.TABLET} {
    order: -1;
    flex-wrap: nowrap;
    flex: 1;

    ${thickScrollStyles}
  }

  @media ${BREAKPOINTS.DESKTOP} {
    margin-right: auto;
  }
`;

export const videoStyles = css`
  border-radius: 12px;
`;

import DataProvider from '@core/components/DataProvider';
import { IAlbum } from '@core/models/media';

import AlbumModal from '@features/media-bank/photos/components/AlbumModal';
import PrimaryAlbum from '@features/media-bank/photos/components/PrimaryAlbum';
import SecondaryAlbum from '@features/media-bank/photos/components/SecondaryAlbum';
import { selectAlbums } from '@features/media-bank/photos/store/albums';

import { useAlbumModal } from '../AlbumModal/hooks';

import * as UI from './Photos.styles';

const Photos = () => {
  const { activeAlbum, openAlbumModal, closeAlbumModal } = useAlbumModal();

  return (
    <DataProvider<IAlbum[]>
      noDataFallback={<></>}
      selector={selectAlbums}
    >
      {({ data }) => {
        if (!data || !data.length) {
          return <></>;
        }

        const [primaryAlbum, ...secondaryAlbums] = data;

        return (
          <>
            <UI.AlbumsContainer>
              <PrimaryAlbum
                album={primaryAlbum}
                styles={UI.PrimaryAlbumStyles}
                onClick={openAlbumModal}
              />

              {secondaryAlbums.length > 0 && (
                <UI.SecondaryAlbumsWrapper>
                  <UI.SecondaryAlbums
                    count={secondaryAlbums.length}
                  >
                    {secondaryAlbums.map((album) => (
                      <SecondaryAlbum
                        album={album}
                        key={album.id}
                        styles={UI.SecondaryAlbumStyles}
                        onClick={openAlbumModal}
                      />
                    ))}
                  </UI.SecondaryAlbums>
                </UI.SecondaryAlbumsWrapper>
              )}
            </UI.AlbumsContainer>

            {activeAlbum && (
              <AlbumModal
                album={activeAlbum}
                onClose={closeAlbumModal}
              />
            )}
          </>
        );
      }}
    </DataProvider>
  );
};

export default Photos;

import styled, { css } from 'styled-components';

import { PlayIcon, VideoCameraIcon } from '@core/UI/Icons';
import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

import Card from '@features/media-bank/common/Card';

export const Container = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  min-width: 210px;
  height: 150px;
  padding: 16px;
  cursor: pointer;

  @media ${BREAKPOINTS.TABLET} {
    min-width: 219px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    min-width: auto;
    height: 200px;
    gap: 15px;
    padding: 18px 24px;
  }

  ${({ styles }) => styles}
`;

export const Header = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 10px 25px;
`;

export const Name = styled(Typography).attrs({ type: 'p2', color: 'white', weight: 'bold' })`
  max-width: 600px;
  margin-top: auto;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;

  @media ${BREAKPOINTS.DESKTOP} {
    font-size: 18px;
    line-height: 24px;
  }
`;

export const VideoCamera = styled(VideoCameraIcon)`
  display: none;

  @media ${BREAKPOINTS.TABLET} {
    display: block;
  }
`;

export const Play = styled(PlayIcon)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: none;

  @media ${BREAKPOINTS.TABLET} {
    display: block;
  }
`;

export const TagGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;

  @media ${BREAKPOINTS.TABLET} {
    width: auto;
  }
`;

export const primaryStyles = css`
  width: 100%;
  height: 350px;
  padding: 24px;
  flex-direction: column-reverse;

  ${Header} {
    justify-content: start;
  }

  ${Name} {
    font-size: 24px;
    line-height: 32px;
    -webkit-line-clamp: 3;

    @media ${BREAKPOINTS.DESKTOP} {
      font-size: 32px;
      line-height: 42px;
    }
  }

  ${Play} {
    top: 39%;
    display: block;

    @media ${BREAKPOINTS.TABLET} {
      top: 50%;
      font-size: 30px;
    }

    @media ${BREAKPOINTS.DESKTOP} {
      font-size: 50px;
    }
  }

  ${VideoCamera} {
    display: block;
  }

  @media ${BREAKPOINTS.TABLET} {
    height: 415px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    height: 610px;
  }
`;

import ImageNext from 'next/image';
import styled from 'styled-components';

import { Layout as LayoutUI } from '@core/UI/Layout';
import { Typography } from '@core/UI/Typography';
import { BREAKPOINTS } from '@core/constants/styles';

const MOBILE_GRID_GAP = 12;
const GRID_GAP = 24;

export const Layout = styled(LayoutUI)`
  padding-top: 0;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding-top: 40px;
  }
`;

export const NewsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: ${MOBILE_GRID_GAP}px;

  @media ${BREAKPOINTS.TABLET} {
    grid-gap: ${GRID_GAP}px;
  }

  @media ${BREAKPOINTS.DESKTOP} {
    grid-template-columns: repeat(2, 1fr);
  }
`;

export const News = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  height: 192px;
  padding: 10px 16px;
  border-radius: 8px;
  background-size: cover;
  z-index: 1;
  cursor: pointer;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 41, 80, 0.4);
    border-radius: 8px;
    z-index: -1;
  }

  @media ${BREAKPOINTS.TABLET} {
    justify-content: flex-start;
    align-items: flex-start;
    height: 260px;
    padding: 26px 24px 20px 24px;
  }
`;

export const NewsMeta = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.TABLET} {
    margin-bottom: auto;
  }
`;

export const NewsDate = styled(Typography).attrs({ type: 'p2', color: 'white' })``;

export const NewsTitle = styled(Typography).attrs({ type: 'h2', color: 'white', lineHeight: 28 })`
  display: none;
  margin-top: 6px;
  font-size: 20px;

  @media ${BREAKPOINTS.TABLET} {
    display: block;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    font-size: 28px;
    line-height: 32px;
  }
`;

export const NewsText = styled(Typography).attrs({ type: 'p2', color: 'manatee' })`
  display: none;
  margin-top: 12px;
  padding: 0 24px;
  overflow: hidden;
  white-space: pre-line;

  @media ${BREAKPOINTS.TABLET} {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 0 63px 0 24px;
  }
`;

export const NewsMobile = styled.div`
  @media ${BREAKPOINTS.TABLET} {
    display: none;
  }
`;

export const NewsMobileTitle = styled(Typography).attrs({ type: 'h3', color: 'riverBlue' })`
  margin-top: 6px;
`;

export const NewsMobileText = styled(Typography).attrs({ type: 'p2', color: 'manatee' })`
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin-top: 8px;
  overflow: hidden;
  white-space: pre-line;
`;

export const Image = styled(ImageNext).attrs({ fill: true })`
  z-index: -2;
  object-fit: cover;
`;

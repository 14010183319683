import React from 'react';

import Modal from '@core/UI/Modal';
import { EModalNames } from '@core/UI/Modal/constants';
import { Typography } from '@core/UI/Typography';

import * as UI from './EncyclopediaInfoModal.styles';

const EncyclopediaInfoModal = () => (
  <Modal name={EModalNames.ENCYCLOPEDIA_INFO} styles={UI.ModalStyles}>
    <Typography type="h2">
      О разделе
    </Typography>

    <Typography styles={UI.TextStyles} type="p1">
      Энциклопедия собрана на основе Большого строительного словаря
      под общей редакцией члена-корреспондента Российской академии архитектуры
      и строительных наук, заслуженного строителя РФ, доктора технических наук,
      главного научного сотрудника НИИСФ РААСН
      <br />
      <b>Римшина Владимира Ивановича.</b>
    </Typography>

    <Typography type="p1">
      Авторы:
      {' '}
      <b>В. И. Римшин, Е.С. Кецко, П.С. Трунтов</b>
    </Typography>
  </Modal>
);

export default EncyclopediaInfoModal;

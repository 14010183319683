import DataProvider from '@core/components/DataProvider';
import { IMaterialVideo } from '@core/models/media';
import { formatDateShort } from '@core/utils/date';
import { getPublicFileUrl } from '@core/utils/downloadFile';

import { selectVideoArchive } from '../../store/videoArchive';
import VideoModal from '../VideoModal';
import { useVideoModal } from '../VideoModal/hooks';

import * as UI from './Video.styles';
import Card from './components/Card';

const Video = () => {
  const { selectedVideo, openVideoModal, closeVideoModal } = useVideoModal();

  const handleSelectedVideo = (video: IMaterialVideo) => () => {
    openVideoModal(video);
  };

  return (
    <DataProvider
      noDataFallback={<UI.EmptyMessage tag="p">Видеоматериалы отсутствуют.</UI.EmptyMessage>}
      selector={selectVideoArchive}
    >
      {({ data: videoList }) => {
        if (!videoList || videoList.length === 0) {
          return null;
        }

        const [primaryVideo, ...secondaryVideos] = videoList;

        return (
          <>
            <UI.Container>
              <Card
                isPrimary
                date={formatDateShort(primaryVideo.date)}
                name={primaryVideo.name}
                previewId={primaryVideo.previewFile.id}
                tags={primaryVideo.tags || []}
                onClick={handleSelectedVideo(primaryVideo)}
              />
              <UI.VideoList>
                {secondaryVideos.map((video) => (
                  <Card
                    date={formatDateShort(video.date)}
                    key={video.id}
                    name={video.name}
                    previewId={video.previewFile.id}
                    onClick={handleSelectedVideo(video)}
                  />
                ))}
              </UI.VideoList>
            </UI.Container>
            {selectedVideo && (
              <VideoModal
                date={formatDateShort(selectedVideo.date)}
                description={selectedVideo.description}
                id={selectedVideo.id}
                name={selectedVideo.name}
                tags={selectedVideo.tags}
                videoUrl={getPublicFileUrl(selectedVideo.file.id)}
                onClose={closeVideoModal}
              />
            )}
          </>
        );
      }}
    </DataProvider>
  );
};

export default Video;

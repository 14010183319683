import * as UI from './Tag.styles';
import { ITagProps, TSize } from './types';

const getTypographyType = (size: TSize) => {
  switch (size) {
    case 'medium':
      return 'p2';
    case 'small':
    default:
      return 'p3';
  }
};

const Tag = (props: ITagProps) => {
  const { size = 'small', variant = 'default', children, ...rest } = props;

  return <UI.Tag size={size} tag="span" type={getTypographyType(size)} variant={variant} {...rest}>{children}</UI.Tag>;
};

export default Tag;

import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';
import { format } from '@core/utils/date';
import { getPublicFileUrl } from '@core/utils/downloadFile';

import MetaTypeIcon from '@features/landing/UI/MetaTypeIcon';

import * as UI from './CarouselSlide.styles';
import { ICarouselSlideProps } from './types';

const CarouselSlide = (props: ICarouselSlideProps) => {
  const {
    news,
    onNewsClick,
  } = props;
  const {
    title,
    imagesIds = [],
    videoIds = [],
    filesIds = [],
    createdAt,
  } = news;
  const [backgroundId] = imagesIds;

  const isDesktop = useMedia(BREAKPOINTS.DESKTOP_LG);

  const handleOnClick = () => {
    onNewsClick(news);
  };

  return (
    <UI.Container onClick={handleOnClick}>
      <UI.SlideContainer>
        <UI.Image alt={title} src={getPublicFileUrl(backgroundId)} />
        {isDesktop && (
          <UI.SlideHeader>
            {title}
          </UI.SlideHeader>
        )}

        <UI.SlideMeta>
          <UI.SlideDate>
            {format(createdAt, 'DD MMM YYYY')}
          </UI.SlideDate>

          {isDesktop && (
            <UI.SlideMetaType>
              <MetaTypeIcon
                filesIds={filesIds}
                videoIds={videoIds}
              />
            </UI.SlideMetaType>
          )}
        </UI.SlideMeta>
      </UI.SlideContainer>

      {!isDesktop && (
        <UI.MobileHeader>
          {title}
        </UI.MobileHeader>
      )}
    </UI.Container>
  );
};

export default CarouselSlide;

import { ReactNode } from 'react';

import EmailIcon from '@core/assets/icons/email.svg';
import OdnoklassnikiIcon from '@core/assets/icons/odnoklassniki.svg';
import TelegramIcon from '@core/assets/icons/telegram.svg';
import VKIcon from '@core/assets/icons/vk.svg';
import { INews } from '@core/models/news';

import { buildNewsShareItem } from './helpers';

interface IShareItem {
  id: number;
  Icon: ReactNode;
  buildHref: (news: INews) => string;
}

export const data: IShareItem[] = [
  {
    id: 1,
    Icon: <TelegramIcon />,
    buildHref: (news) => {
      const { title, link } = buildNewsShareItem(news);
      return `https://t.me/share/url?url=${link}&text=${title}`;
    },
  },
  {
    id: 2,
    Icon: <EmailIcon />,
    buildHref: (news) => {
      const { title, text, link } = buildNewsShareItem(news);
      return `mailto:?subject=${title}&body=${text}...%0A%0A${link}`;
    },
  },
  {
    id: 3,
    Icon: <VKIcon />,
    buildHref: (news) => {
      const { title, link } = buildNewsShareItem(news);
      return `https://vk.com/share.php?url=${link}&title=${title}`;
    },
  },
  {
    id: 4,
    Icon: <OdnoklassnikiIcon />,
    buildHref: (news) => {
      const { title, link } = buildNewsShareItem(news);
      return `https://connect.ok.ru/offer?url=${link}&title=${title}`;
    },
  },
];

import DocumentIcon from '@features/landing/assets/icons/document.svg';
import VideoIcon from '@features/landing/assets/icons/video.svg';

import * as UI from './MetaTypeIcon.styles';
import { IMetaTypeIconProps } from './types';

const MetaTypeIcons = (props: IMetaTypeIconProps) => {
  const {
    color = 'white',
    filesIds = [],
    videoIds = [],
    className,
  } = props;

  const hasFiles = filesIds.length > 0;
  const hasVideos = videoIds.length > 0;

  const renderMetaTypeIcon = () => {
    if (hasFiles) {
      return <DocumentIcon />;
    }

    if (hasVideos) {
      return <VideoIcon />;
    }
  };

  if (!hasFiles && !hasVideos) {
    return null;
  }

  return (
    <UI.Container $color={color} className={className}>
      {renderMetaTypeIcon()}
    </UI.Container>
  );
};

export default MetaTypeIcons;

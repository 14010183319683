import { css } from 'styled-components';

import { BREAKPOINTS } from '@core/constants/styles';

export const ModalStyles = css`
  top: initial;

  @media ${BREAKPOINTS.TABLET}{
    max-width: 500px;
  }
`;

export const TextStyles = css`
  margin: 16px 0 32px;
`;

import { LANDING_ANCHORS_ROUTES } from '@core/constants/routes';

import ComfortEnvironment from '@features/nationalProjects/components/ComfortEnvironment';

import * as UI from './NationalGoal.styles';

const NationalGoal = () => (
  <UI.Layout id={LANDING_ANCHORS_ROUTES.NATIONAL_GOALS}>
    <ComfortEnvironment />
  </UI.Layout>
);

export default NationalGoal;

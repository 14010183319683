import Tag from '@core/UI/Tag';
import { Typography } from '@core/UI/Typography';

import * as UI from './Card.styles';
import { ICardProps } from './types';

const Card = (props: ICardProps) => {
  const { isPrimary = false, previewId, date, name, tags, onClick, styles } = props;
  const containerStyles = [styles, isPrimary ? UI.primaryStyles : undefined];

  return (
    <UI.Container backgroundImageId={previewId} styles={containerStyles} onClick={onClick}>
      <UI.Header>
        <Typography color="aquaHaze" type="p2">{date}</Typography>
        <UI.VideoCamera />
        {((isPrimary && tags) && (
          <UI.TagGroup>
            {tags.map((tag) => <Tag color="softGrey" key={tag.id} size="medium" variant="outline">{tag.name}</Tag>)}
          </UI.TagGroup>
        ))}
      </UI.Header>
      <UI.Name tag="span">{name}</UI.Name>
      <UI.Play />
    </UI.Container>
  );
};

export default Card;

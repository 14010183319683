import { Layout } from '@core/UI/Layout';

// import Newsletter from './components/Newsletter';
import OrganizationsSection from './components/OrganizationsSection';

const Organizations = () => (
  <Layout>
    <OrganizationsSection />
    {/* Временно скрыто пока не готов бэк */}
    {/* <Newsletter /> */}
  </Layout>
);

export default Organizations;

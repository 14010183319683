import styled from 'styled-components';

import { BREAKPOINTS } from '@core/constants/styles';

export const Container = styled.div`
  display: flex;
  flex-direction: column;

  @media ${BREAKPOINTS.DESKTOP_LG} {
    flex-direction: row-reverse;
  }
`;

import Image from 'next/image';

import PlayIcon from '@core/assets/icons/play.svg';

import { IVideoProps } from '@features/strategy/components/AntiCrisisProgram/components/Video/types';

import * as UI from './Video.styles';

const Video = (props: IVideoProps) => {
  const { imgPreview, openModal } = props;

  return (
    <UI.Preview onClick={openModal}>
      <Image fill alt="Превью" src={imgPreview} />

      <PlayIcon />
    </UI.Preview>
  );
};

export default Video;

import Tooltip from '@core/UI/Tooltip';
import { BREAKPOINTS } from '@core/constants/styles';
import { useMedia } from '@core/hooks/useMedia';

import TooltipContent from '../TooltipContent';

import * as UI from './Share.styles';
import { IShareProps } from './types';

const Share = (props: IShareProps) => {
  const { news } = props;
  const isTablet = useMedia(BREAKPOINTS.TABLET);

  return (
    <>
      <Tooltip
        placement={isTablet ? 'right' : 'bottomRight'}
        tooltipContent={<TooltipContent news={news} />}
        trigger="click"
        zIndex={10}
      >
        <UI.ShareButton title="Поделиться">
          <UI.ShareIcon />
        </UI.ShareButton>
      </Tooltip>
    </>
  );
};

export default Share;

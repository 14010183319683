import { YMInitializer } from 'react-yandex-metrika';

import { isDevelopment } from '@core/utils/env';

const YandexMetrika = () => {
  const id = Number(process.env.NEXT_PUBLIC_YANDEX_METRIKA_ID);

  if (Number.isNaN(id) || isDevelopment()) {
    return null;
  }

  return <YMInitializer accounts={[id]} />;
};

export default YandexMetrika;

import styled from 'styled-components';

import { Layout as LayoutUI } from '@core/UI/Layout';
import colors from '@core/constants/colors';
import { BREAKPOINTS, DEVICES, PAGE_PADDINGS } from '@core/constants/styles';

import { ICarouselContainerStylesProps } from './types';

export const Layout = styled(LayoutUI)`
  @media ${DEVICES.MOBILE}, ${DEVICES.TABLET} {
    padding-left 0px;
    padding-right 0px;
    overflow: hidden;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding-bottom: 0px;
  }
`;

export const CarouselContainer = styled.div<ICarouselContainerStylesProps>`
  padding: 12px ${PAGE_PADDINGS.MOBILE}px 0px ${PAGE_PADDINGS.MOBILE}px;
  position: relative;

  @media ${BREAKPOINTS.TABLET} {
    padding-left: ${PAGE_PADDINGS.TABLET}px;
    padding-right: ${PAGE_PADDINGS.TABLET}px;
  }

  @media ${BREAKPOINTS.DESKTOP_LG} {
    padding: 0px;
  }

  .swiper {
    width: 100%;
    height: 100%;
    overflow: visible;

    @media ${BREAKPOINTS.DESKTOP_LG} {
      height: 650px;
      overflow: hidden;
    }
  }

  .swiper-slide {
    width: 280px;

    @media ${BREAKPOINTS.TABLET} {
      width: 429px;
    }

    @media ${BREAKPOINTS.DESKTOP_LG} {
      width: 1028px;
      height: 621px;
      z-index: 1;
      pointer-events: none;
    }

    &-active {
      @media ${BREAKPOINTS.DESKTOP_LG} {
        z-index: 2 !important;
        pointer-events: auto;
      }
    }
  }

  &&& {
   .swiper-pagination {
     bottom: calc(100% + 6px);
     line-height: 26px;

     @media ${BREAKPOINTS.DESKTOP_LG} {
       bottom: -6px;
     }
   }

    .swiper-pagination-bullet {
      width: 12px;
      height: 12px;
      background: ${colors.riverBlue};
    }

    .swiper-pagination-bullet-active {
      background: ${colors.chambrayBlue};
    }
  }
`;
